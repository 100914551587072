
import { Options, Vue } from "vue-class-component";
import Title from "@/app/ui/components/data-wrapper/title.vue";
import debounce from "lodash/debounce";
import formattedNPWP from "@/app/infrastructures/misc/common-library/FormattedNPWP";
import formatInputFloat from "@/app/infrastructures/misc/common-library/FormatInputFloat";
import Tooltip from "@/app/ui/components/tooltip/index.vue";
import router from "@/app/ui/router";
import { PartnerController } from "@/app/ui/controllers/PartnerController";
import { LocationController } from "@/app/ui/controllers/LocationController";
import ModalAddBank from "./modal-add-bank.vue";
import { PosController } from "@/app/ui/controllers/PosController";
import { RequestListPartner } from "@/data/payload/api/PartnerApiRequest";
import { PosData } from "@/domain/entities/Pos";
import FileAttachment from "./file-attachment.vue";
import { AccountController } from "@/app/ui/controllers/AccountController";
import formatInputMoney from "@/app/infrastructures/misc/common-library/FormatInputMoney";

@Options({
  components: {
    Title,
    Tooltip,
    ModalAddBank,
    FileAttachment
  },
  computed: {
    getTitle() {
      return this.$route.meta.name;
    }
  },
  beforeRouteLeave(to: any, from: any, next: any) {
    if (to.name === "login") {
      next();
    }
    if (!this.openSuccess) {
      this.onOpenClosePage(to);
      if (this.answer) {
        this.answer = false;
        next();
      } else {
        next(false);
      }
    } else {
      this.setModalSuccess(false);
      next();
    }
  }
})
export default class Add extends Vue {
  props: any = this.$props;

  get formType() {
    return this.$route.meta.form;
  }

  // add bank midtrans enhancement
  get showAddBank() {
    return PartnerController.showAddBank;
  }
  onShowAddBank(val: boolean) {
    PartnerController.setShowAddBank(val);
  }

  onDeleteBank() {
    PartnerController.deleteBank();
  }

  mounted() {
    this.fetchDistricts("");
    this.filterConsolidators("");
    this.fecthCityListData();
  }

  unmounted() {
    PartnerController.onResetForm();
  }

  // back
  goBack() {
    router.push(`/partner/${this.$route.meta.form}`);
  }

  isEditTerminated = false;
  newPath = "";
  answer = false;

  onOpenClosePage(to: any) {
    this.isEditTerminated = true;
    this.newPath = to.path;
  }

  handlerClose() {
    this.isEditTerminated = false;
    this.answer = true;
    this.$router.push(this.newPath);
  }

  handlerCancelClose() {
    this.answer = false;
    this.isEditTerminated = false;
  }

  // saving
  openConfirmation = false;
  createConfirmation() {
    this.openConfirmation = true;
  }

  openSuccess = false;
  async onCreate() {
    this.openConfirmation = false;
    const response = await PartnerController.addPartner({
      type: this.extractAccountType(),
      version: this.extractAccountType() === "pos" ? "v2" : "v1",
      partnerName: this.form.consolidatorName,
      partnerExternalCode: "",
      partnerAddress: this.form.consolidatorAddress,
      partnerDefaultDistrict: this.form.districts.code,
      partnerContractStart: new Date(this.form.startDate.setUTCHours(24))
        .toISOString()
        .slice(0, 10),
      partnerContractEnd: new Date(this.form.endDate.setUTCHours(24))
        .toISOString()
        .slice(0, 10),
      partnerContactName: this.form.contactName,
      partnerContactJobTitle: this.form.contactLevel,
      partnerContactEmail: this.form.contactEmail,
      partnerContactPhoneNumber: this.form.contactPhones
        .map((phone: any) => phone.no)
        .join(", "),
      partnerBeneficiaryBankName: this.form.bank.bankCode,
      partnerBeneficiaryAccountNumber: this.form.bank.accountNo,
      partnerBeneficiaryAccountName: this.form.bank.accountName,
      partnerBeneficiaryEmail: this.form.bank.accountEmail,
      partnerBeneficialAccountLabel: this.form.bank.bankName,
      partnerPhoneNumber: this.form.phone,
      partnerTaxNumber: this.form.taxNumber,
      partnerParentId: this.form.consolidators.id || undefined,
      partnerCity:
        this.form.cityExclude.length > 0
          ? this.form.cityExclude.map((city: any) => city.code)
          : undefined,
      partnerLatitude: this.form.latitude,
      partnerLongitude: this.form.longitude,
      partnerReferrerCode: this.form.referralCode,
      partnerPosType: this.form.typePos,
      partnerPosParentId: this.form.parentPos?.id,
      partnerIsCodDelivery: this.form.partnerIsCodDelivery,
      partnerIsPcu: this.form.partnerIsPcu,
      partnerPosBranchCommission:
        this.form.typePos === "branch"
          ? Number(this.form.branchCommission?.replace(",", "."))
          : 0,
      partnerPosReverseJourney: this.form.partnerPosReverseJourney.id || 0,
      partnerIsCodBooking: this.form.partnerIsCodBooking,
      partnerPosAttachFiles: this.formType === 'pos' ? this.form.partnerPosAttachFiles?.filter((item: string) => item) : [],
      partnerPosSaldoDeposit: this.formType === 'pos' ? Number(this.form.debitBalance?.replaceAll(".", "")) : 0
    });
    if (this.errorMessageAdd === "Referal Code not found") {
      this.checkReferralCodeResult = false;
      const refs: any = this.$refs;
      refs.formAddPos.scroll({
        top: refs.formAddPos.scrollHeight - refs.formAddPos.offsetHeight,
        behavior: "smooth"
      });
    }
    this.openSuccess = !!response;
  }

  extractAccountType() {
    const route = this.$route.meta.form as string;
    const accounType: any = {
      "consolidator": "console",
      "sub-consolidator": "sub-console"
    }
    
    return accounType[route] ?? "pos"
  }

  get isLoading() {
    return PartnerController.isLoading;
  }

  get isError() {
    return PartnerController.isError;
  }

  get errorCause() {
    return PartnerController.errorCause;
  }

  get openSuccessFromState() {
    return PartnerController.modalSuccess;
  }

  handleError() {
    PartnerController.setIsError(false);
    PartnerController.setErrorCause("");
  }

  onCloseConfirmation() {
    this.openConfirmation = false;
  }

  onCloseSuccess() {
    PartnerController.setModalSuccess(false);
    this.goBack();
  }

  setModalSuccess(value: boolean) {
    PartnerController.setModalSuccess(false);
    this.openSuccess = value;
  }

  //partner name
  get partnerName() {
    if (this.$route.meta.form === "consolidator") {
      return "Nama Consolidator";
    } else if (this.$route.meta.form === "sub-consolidator") {
      return "Nama Sub Consolidator";
    } else {
      return "Nama POS";
    }
  }

  // tooltip
  isOpenTooltipAddCity = false;

  // error
  error = {
    phone: false,
    taxNumber: false,
    contactPhone: false,
    contactEmail: false,
    bankEmail: false,
    accountBankNo: false,
    commision: false
  };

  // form
  get form() {
    return PartnerController.formAdd;
  }

  // districts
  fetchDistricts = debounce((search: string) => {
    LocationController.getDistrictList({
      search: search,
      status: "",
      page: 1,
      limit: 10,
      cache: true
    });
  }, 250);

  get districts() {
    return LocationController.districtData.districtData.map(e => ({
      id: e.id,
      name: `${e.name}, ${e.cityName}`,
      code: `${e.code}`,
      cityCode: `${e.cityCode}`
    }));
  }

  get isLoadingDistricts() {
    return LocationController.isLoadingDistrict;
  }

  // districts toppings
  get cityOver() {
    if (this.form.cityExclude.length > 5) {
      this.form.cityExclude.pop();
      return true;
    }

    return false;
  }

  get isLoadingCity() {
    return LocationController.isLoading;
  }

  fecthCityListData() {
    LocationController.getCityList({
      search: "",
      status: "",
      page: 1,
      limit: 10
    });
  }

  get cityListData() {
    return LocationController.cityData.cityData.map(e => ({
      code: e.code,
      name: `${e.name}`
    }));
  }

  filterCityList(search: string) {
    if (search.length > 2 || !search) {
      LocationController.getCityList({
        search: search,
        status: "",
        page: 1,
        limit: 10
      });
    }
  }

  // consolidators
  filterConsolidators = debounce((search: string) => {
    PartnerController.getConsolidatorsByCity({
      search: search,
      page: 1,
      limit: 10,
      cityCode: this.cityCode === undefined ? "" : this.cityCode,
      type: !this.partnerType ? "console" : this.partnerType,
      status: this.partnerStatus
    });
  }, 250);

  get consolidators() {
    if (this.$route.meta.form === "sub-consolidator") {
      return PartnerController.consolidatorsByCity.consolidatorByCityData.map(
        e => ({
          id: e.id,
          name: `${e.name}`
        })
      );
    } else if (this.$route.meta.form === "pos") {
      if (this.partnerType) {
        return PartnerController.consolidatorsByCity.consolidatorByCityData.map(
          e => ({
            id: e.id,
            name: `${e.name}`
          })
        );
      }

      return [];
    } else {
      return [];
    }
  }

  get isLoadingConsolidators() {
    return PartnerController.loadingConsolidatorsByCity;
  }

  cityCode = "";
  partnerType = "";
  partnerStatus = "";
  getConsolidatorsByCity(obj: any) {
    this.form.consolidators = "";
    this.errorKecamatanKota = false;
    this.errorCaptionKecamatanKota = "";
    this.cityCode = obj.cityCode;

    PartnerController.getConsolidatorsByCity({
      search: "",
      page: 1,
      limit: 10,
      cityCode: this.cityCode === undefined ? "" : this.cityCode,
      type:
        this.$route.meta.form === "sub-consolidator"
          ? "console"
          : this.partnerType,
      status: this.partnerStatus
    });

    PosController.setDefaultPosList();
    this.form.parentPos = "";
    this.form.partnerPosReverseJourney = "";
    if (this.$route.meta.form !== "pos") {
      this.filterPartnerPosReverseJourney("");
    }
  }

  // partners area type
  partnerAreaTypeList = [
    {
      name: "Pilih partner area",
      value: ""
    },
    {
      name: "Consolidator",
      value: "console"
    },
    {
      name: "Sub Consolidator",
      value: "sub-console"
    }
  ];

  isOpenPartnerType = false;
  onOpenSelectPartnerType() {
    this.isOpenPartnerType = true;
  }

  onCloseSelectPartnerType() {
    this.isOpenPartnerType = false;
  }

  onSelectPartnerType(key: string, value: string) {
    this.onCloseSelectPartnerType();
    this.form.partnerArea = value;
    this.form.consolidators = "";
    this.partnerType = value;
    PartnerController.getConsolidatorsByCity({
      search: "",
      page: 1,
      limit: 10,
      cityCode: this.cityCode === undefined ? "" : this.cityCode,
      type: this.partnerType === "" ? "" : this.partnerType,
      status: this.partnerStatus
    });
  }

  formatLongLat(value: string) {
    return value.replace(/[^0-9+-.]+/g, "").replace(",", "");
  }

  formatCommission(value: string) {
    return formatInputFloat(value, 2);
  }

  errorCaptionCommision = "";
  validateCommision(value: string) {
    value = value.replace(/,/g, ".");
    if (Number(value) < 0) {
      this.error.commision = true;
      this.errorCaptionCommision = "Komisi tidak boleh nol/kosong";
    } else if (Number(value) > 100) {
      this.error.commision = true;
      this.errorCaptionCommision = "Komisi tidak boleh lebih dari 100%";
    } else {
      this.error.commision = false;
    }
  }

  // phone
  formatNumber(value: string) {
    return value.replace(/[\D]+/g, "");
  }

  validatePhone(value: string) {
    this.error.phone = !/^\d+$/.test(value);
  }

  validateContactPhone(index: number) {
    this.form.contactPhones[index].error = !/^\d+$/.test(
      this.form.contactPhones[index].no
    );
  }

  addPhone() {
    if (this.form.contactPhones.length > 2) {
      return;
    }

    this.form.contactPhones.push({
      no: "",
      error: false
    });
  }

  removePhone(index: number) {
    this.form.contactPhones.splice(index, 1);
  }

  get isMaxContactPhone() {
    return this.form.contactPhones.length < 3;
  }

  // npwp
  formatTax(value: string) {
    return formattedNPWP(value.replace(/[\D]+/g, ""));
  }

  validateTaxNumber(value: string) {
    this.error.taxNumber = !/[0-9-.]+/.test(value);
    if (value.length < 20) {
      this.error.taxNumber = true;
    }
  }

  // bank
  get bankList() {
    return PartnerController.partnerBank.map(bank => {
      return { name: bank.bankName, value: bank.bankCode };
    });
  }
  fetchListBank() {
    PartnerController.getListPartnerBank();
  }
  get loadingBank() {
    return PartnerController.isLoadingBank;
  }

  // email
  validateEmail(value: string) {
    this.error.contactEmail = !/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(value);
  }

  validateEmailBank(value: string) {
    this.error.bankEmail = !/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(value);
  }

  // account bank no
  validateAccountNo(value: string) {
    this.error.accountBankNo = !/^\d+$/.test(value);
  }

  // validator
  get isFormValid() {
    if (
      this.$route.meta.form === "consolidator" &&
      this.form.consolidatorName &&
      this.form.consolidatorAddress &&
      this.form.districts?.id &&
      this.form.phone &&
      this.form.contactName &&
      this.form.contactLevel &&
      this.form.contactEmail &&
      this.form.contactPhones[0].no &&
      this.form.startDate &&
      this.form.endDate &&
      this.form.latitude &&
      this.form.longitude &&
      !this.error.phone &&
      !this.error.taxNumber &&
      !this.error.contactEmail
    ) {
      return false;
    } else if (
      this.$route.meta.form === "sub-consolidator" &&
      this.form.consolidatorName &&
      this.form.consolidatorAddress &&
      this.form.consolidators?.id &&
      this.form.districts?.id &&
      this.form.contactName &&
      this.form.contactLevel &&
      this.form.contactEmail &&
      this.form.contactPhones[0].no &&
      this.form.startDate &&
      this.form.endDate &&
      this.form.latitude &&
      this.form.longitude &&
      !this.error.contactEmail
    ) {
      return false;
    } else
      return !(
        this.$route.meta.form === "pos" &&
        this.form.consolidatorName &&
        this.form.consolidatorAddress &&
        this.form.districts?.id &&
        this.form.contactName &&
        this.form.contactLevel &&
        this.form.contactEmail &&
        this.form.contactPhones[0].no &&
        this.form.startDate &&
        this.form.endDate &&
        this.form.partnerArea &&
        this.form.consolidators?.id &&
        this.form.latitude &&
        this.form.longitude &&
        !this.error.contactEmail &&
        this.validateReferralCode &&
        this.form.typePos &&
        !this.isParentPosDipickup &&
        !this.isParentPos &&
        !this.validateFiles &&
        !this.isLoadingUpload
      );
  }

  get validateFiles() {
    const newFiles = this.form.partnerPosAttachFiles?.filter((item: string) => item);
    return newFiles?.length < 3
  }

  errorCaptionKecamatanKota = "";
  errorKecamatanKota = false;
  get errorMessageAdd() {
    if (
      PartnerController.errorMessageAdd ===
      "Sub Console di district ini sudah ada"
    ) {
      this.errorCaptionKecamatanKota = "Kecamatan Sudah Tersedia";
      this.errorKecamatanKota = true;
      return "Kecamatan kota yang Anda pilih sudah tersedia untuk Sub Consolidator lain.";
    }
    return PartnerController.errorMessageAdd;
  }

  checkReferralCodeResult = true;
  onChangeReferralCode = debounce(async (value: string) => {
    if (!value) {
      this.checkReferralCodeResult = true;
      return;
    }
    if (value.length < 6 || value.length > 6) {
      this.checkReferralCodeResult = false;
      return;
    }
    this.checkReferralCodeResult = await PartnerController.checkReferralCode(
      this.form.referralCode
    );
  }, 500);
  get validateReferralCode() {
    if (!this.form.referralCode) return true;
    return this.checkReferralCodeResult;
  }

  onCheckedCODDeliveryPartner(value: any) {
    this.form.partnerIsCodDelivery = value;
  }

  onCheckedCODBookingPartner(value: any) {
    this.form.partnerIsCodBooking = value;
  }

  onCheckedPartnerIsPCU(value: any) {
    this.form.partnerIsPcu = value;
  }

  // tipe pos
  openTypePos = false;
  typePos = [
    { name: "Pilih tipe", value: "" },
    { name: "POS Parent", value: "parent" },
    { name: "POS Child", value: "branch" },
    { name: "POS Dipickup", value: "pickup" }
  ];

  onChangeTypePos(name: string, val: string) {
    this.form.typePos = val;
    this.form.parentPos = "";
    this.openTypePos = false;
  }

  filterPartnerPosReverseJourney = debounce((search: string) => {
    PosController.getPosList(
      new RequestListPartner({
        page: 1,
        limit: 10,
        type: "pos",
        cityCode: this.form.districts?.cityCode,
        search: search,
        posType: "parent",
        contractStatus: "active"
      })
    );
  }, 250);

  get posReverseJourney() {
    return PosController.posList.data?.map((data: PosData) => ({
      id: data.id,
      name: `${data.code}-${data.name}`
    }));
  }

  // parent pos
  get isParentPos() {
    if (this.form.typePos) {
      return (
        this.form.typePos === "branch" &&
        !this.form.parentPos?.id &&
        !this.form.branchCommission
      );
    }

    return true;
  }

  get isParentPosDipickup() {
    if (this.form.typePos) {
      return (
        this.form.typePos === "pickup" &&
        !this.form.parentPos?.id 
      );
    }

    return true;
  }

  filterParentPos = debounce((search: string) => {
    PosController.getPosList(
      new RequestListPartner({
        page: 1,
        limit: 10,
        type: "pos",
        cityCode: this.form.districts?.cityCode,
        search: search,
        posType: "parent"
      })
    );
  }, 250);

  get isLoadingParentPos() {
    return PosController.isLoading;
  }

  get parentPos() {
    return PosController.posList.data;
  }

  updateFiles(files: string[]) {
    this.form.partnerPosAttachFiles = files;
  }

  isLoadingUpload = false;
  setLoadingUpload(status: boolean) {
    this.isLoadingUpload = status;
  }

  formatDebitBalance(value: string) {
    return formatInputMoney(value.replace(/[\D]+/g, ""));
  }

  get isFormTypePos() {
    return this.formType === 'pos';
  }
}
